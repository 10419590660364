// LinkResolver.js file

exports.linkResolver = (doc) => {
    // console.log('docType', doc.type)
    if (doc.type === 'page') {


        if (doc.uid === 'home') {
            return '/'
        } else {
            return `/${doc.uid}`
        }
    }
    if (doc.type === 'post') {
        return `${doc.uid}`
    }


    return '/'
}